.AdminTable {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;
  min-width: 70%;
  &.__content {
    display: block;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 0 6px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}
