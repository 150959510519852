.Tag {
  display: inline-block;
  background-color: $color__bg;
  color: $color__grey-dark;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  border-right: 0.5px solid $color__grey;
  &:last-child {
    border-right: none;
  }
  &:hover {
    background-color: $color__grey;
  }
  &--active,
  &--active:hover {
    background-color: $color__cgl;
    color: $color__white;
  }
}
