.header#nav {
  background-color: #1d1d1b;
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  align-items: center;
  display: flex;

  img {
    max-width: 100%;
    display: block;
    height: 50px;
    min-width: 225px;
  }
  a {
    display: inline-block;
    width: 50%;
    &.logout {
      text-align: end;
      padding: 0 20px 0 0;
    }
  }
}
