.volunteer-registration {
  &#show {
    margin: 0;
    padding: 0;
    width: 98%;
    margin: auto;
  }
  background-color: white;
  font: sans-serif;
  width: 70%;
  padding: 40px;
  margin: 50px;
  @media only screen and (max-width: 600px) {
    width: 95%;
    margin: 0 0 20px 0;
    padding: 20px;
    header {
      img {
        display: none;
      }
      h2 {
        width: 100%;
      }
    }

    input {
      width: 90% !important;
    }
  }
  input {
    &.error {
      border: 2px solid red;
    }
  }
  header {
    img {
      max-width: 25%;
      width: 200px;
    }
  }
  .inner {
    border: 2px dotted rgb(161, 161, 161);
    padding: 15px;
  }
  h2 {
    font-size: 25px;
    width: 70%;
    text-align: center;
    display: inline-block;
    margin: 20px 0 20px auto;
  }
  p {
    text-align: justify;
    &.error {
      color: red;
    }
  }
  .main-form {
    p {
      margin: 5px 0;
      font-size: 15px;
      text-align: left;
      font-weight: bold;
      font-style: italic;
    }
    input {
      margin: 0 0 0 10px;
      width: 30%;
    }
    .ui.selection.dropdown {
      width: fit-content;
      &.error {
        border: 2px solid red;
      }
    }
  }
  .minors-form {
    margin: 35px 5px;
    padding: 8px;
    border: 4px double black;
    p {
      text-align: left;
      margin: 10px 0;
      font-size: 15px;
    }
    input {
      margin: 0 0 0 10px;
    }
  }
  .signs-form {
    font-size: 15px;
    font-style: italic;
    input {
      margin: 0 0 0 10px;
      width: 5%;
    }
    textarea {
      width: 100%;
      height: 60px;
    }
    .sigCanvas {
      border: 1px dotted black;
      &.error {
        border: 3px dotted red;
      }
    }
  }
  .captcha {
    &.error iframe {
      border: 1px solid red;
    }
  }
}
