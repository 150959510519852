@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:300,400,700&display=swap);
h1,
h2 {
  margin: 0; }

p small {
  font-size: 0.75em; }

:root {
  font-size: 20px; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  min-height: 100vh;
  margin: 0;
  font-size: 20px; }

h1,
h2,
h3,
h4,
h5,
p,
label,
input,
textarea,
button,
a {
  font-family: "Montserrat", sans-serif !important; }

.App {
  display: grid;
  grid-template: 100px 1fr/1fr;
  min-height: 100vh;
  background-color: #dedede; }

.Content__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: calc(100vh - 100px); }

form {
  background-color: white;
  border-radius: 5px; }

.Registration__form {
  padding: 20px; }
  .Registration__form .Registration__input {
    width: 33%; }

@media only screen and (max-width: 700px) {
  .Registration__input,
  input,
  .input {
    display: block;
    width: 100% !important; } }

@media only screen and (max-width: 600px) {
  .input {
    padding: 5px !important; } }

.admin {
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
  border-radius: 22px;
  grid-column-gap: 1rem;
  grid-template-areas: 'content buttons' 'link link';
  max-width: 80vw;
  padding: 1.1rem; }
  .admin * {
    text-align: center; }
  .admin button {
    margin: 5px !important; }

.Home {
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
  border-radius: 22px;
  grid-column-gap: 1rem;
  grid-template-areas: 'content buttons' 'link link';
  max-width: 80vw;
  padding: 1.1rem; }
  .Home .action_buttons {
    padding: 5px;
    width: 100%; }
    .Home .action_buttons button {
      margin: 5px; }
  .Home__content {
    display: inline-block;
    width: 70%; }
    .Home__content button {
      margin: 10px; }
  .Home__buttons {
    width: 30%;
    display: inline-block;
    text-align: center; }
    .Home__buttons .button {
      margin: 10px 0; }
  .Home__title {
    color: #2596c2;
    font-size: 2.5rem; }
  .Home__button {
    background-color: #2596c2;
    border-radius: 0.6rem;
    color: white;
    display: -webkit-flex;
    display: flex;
    margin: 1rem auto;
    max-width: 300px;
    height: 47px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    transition: 300ms;
    grid-area: buttons; }
    .Home__button:hover {
      background-color: black; }
    .Home__button ._secundary {
      background-color: black; }
  .Home__link {
    grid-area: link;
    text-align: center;
    border-top: 0.5px solid lightgray;
    color: gray;
    padding: 1rem 0;
    align-self: end; }

@media only screen and (max-width: 600px) {
  .Home {
    display: grid;
    grid-template: repeat(3, auto)/1fr;
    grid-template-areas: 'content' 'buttons' 'link';
    -webkit-justify-content: center;
            justify-content: center; }
    .Home__buttons {
      width: 100%; } }

.AdminTable {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;
  min-width: 70%; }
  .AdminTable.__content {
    display: block; }
  .AdminTable table {
    border-collapse: collapse;
    width: 100%; }
  .AdminTable td,
  .AdminTable th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 0 6px; }
  .AdminTable tr:nth-child(even) {
    background-color: #dddddd; }

.Button {
  background-color: #2596c2;
  border-radius: 0.6rem;
  border: 0;
  color: #fff;
  outline: none;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: inherit;
  cursor: pointer;
  transition: 300ms;
  width: 100%;
  max-width: 15rem;
  margin: 15px 0 15px 0;
  text-align: center; }
  .Button:hover {
    background-color: #222; }
  .Button:disabled {
    background-color: #d3d3d3;
    color: #a3a3a3; }
  .Button--tag {
    max-width: 50%; }

.CheckIn {
  width: 70%;
  background-color: #fff;
  border-radius: 22px;
  height: auto;
  padding: 1.1rem; }
  .CheckIn .info {
    margin: 20px 0; }
    .CheckIn .info span {
      margin: 0 10px; }
  @media only screen and (max-width: 700px) {
    .CheckIn {
      width: 95%; }
      .CheckIn .info span {
        width: 100%;
        display: inline-block; } }

.GuestIdForm {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px; }
  .GuestIdForm form {
    max-width: 300px; }
  .GuestIdForm .Registration__input {
    padding: 0; }

.header#nav {
  background-color: #1d1d1b;
  color: white;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 1rem;
  -webkit-align-items: center;
          align-items: center;
  display: flex; }
  .header#nav img {
    max-width: 100%;
    display: block;
    height: 50px;
    min-width: 225px; }
  .header#nav a {
    display: inline-block;
    width: 50%; }
    .header#nav a.logout {
      text-align: end;
      padding: 0 20px 0 0; }

.Registration {
  background-color: #fff;
  border-radius: 22px;
  height: auto;
  max-width: 80vw;
  padding: 1.1rem; }
  .Registration__title {
    color: #2596c2;
    font-size: 2.5rem; }
  .Registration__input {
    padding: 0.3rem 0.5rem 0.3rem 1rem;
    font-size: 20px;
    border-radius: 22px;
    border: none;
    margin: 0.1rem auto; }
    .Registration__input::-webkit-input-placeholder {
      color: #a3a3a3; }
    .Registration__input:-ms-input-placeholder {
      color: #a3a3a3; }
    .Registration__input::placeholder {
      color: #a3a3a3; }
  .Registration__form {
    text-align: center; }
    .Registration__form .dropdown {
      width: 30% !important;
      margin: 15px; }
  .Registration__tags p {
    margin: 5px 0 0 0; }
  .Registration__tags .dia {
    width: 40%;
    display: inline-block; }
    .Registration__tags .dia h3 {
      margin: 10px 0; }

.Tag {
  display: inline-block;
  background-color: #efefef;
  color: #a3a3a3;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  border-right: 0.5px solid #d3d3d3; }
  .Tag:last-child {
    border-right: none; }
  .Tag:hover {
    background-color: #d3d3d3; }
  .Tag--active, .Tag--active:hover {
    background-color: #2596c2;
    color: #fff; }

.print p {
  margin: 0;
  text-align: center; }

.print .barcode {
  text-align: center; }

.dates {
  width: 80%; }
  .dates .Registration__form {
    padding: 20px; }

.volunteer-registration {
  background-color: white;
  font: sans-serif;
  width: 70%;
  padding: 40px;
  margin: 50px; }
  .volunteer-registration#show {
    margin: 0;
    padding: 0;
    width: 98%;
    margin: auto; }
  @media only screen and (max-width: 600px) {
    .volunteer-registration {
      width: 95%;
      margin: 0 0 20px 0;
      padding: 20px; }
      .volunteer-registration header img {
        display: none; }
      .volunteer-registration header h2 {
        width: 100%; }
      .volunteer-registration input {
        width: 90% !important; } }
  .volunteer-registration input.error {
    border: 2px solid red; }
  .volunteer-registration header img {
    max-width: 25%;
    width: 200px; }
  .volunteer-registration .inner {
    border: 2px dotted #a1a1a1;
    padding: 15px; }
  .volunteer-registration h2 {
    font-size: 25px;
    width: 70%;
    text-align: center;
    display: inline-block;
    margin: 20px 0 20px auto; }
  .volunteer-registration p {
    text-align: justify; }
    .volunteer-registration p.error {
      color: red; }
  .volunteer-registration .main-form p {
    margin: 5px 0;
    font-size: 15px;
    text-align: left;
    font-weight: bold;
    font-style: italic; }
  .volunteer-registration .main-form input {
    margin: 0 0 0 10px;
    width: 30%; }
  .volunteer-registration .main-form .ui.selection.dropdown {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .volunteer-registration .main-form .ui.selection.dropdown.error {
      border: 2px solid red; }
  .volunteer-registration .minors-form {
    margin: 35px 5px;
    padding: 8px;
    border: 4px double black; }
    .volunteer-registration .minors-form p {
      text-align: left;
      margin: 10px 0;
      font-size: 15px; }
    .volunteer-registration .minors-form input {
      margin: 0 0 0 10px; }
  .volunteer-registration .signs-form {
    font-size: 15px;
    font-style: italic; }
    .volunteer-registration .signs-form input {
      margin: 0 0 0 10px;
      width: 5%; }
    .volunteer-registration .signs-form textarea {
      width: 100%;
      height: 60px; }
    .volunteer-registration .signs-form .sigCanvas {
      border: 1px dotted black; }
      .volunteer-registration .signs-form .sigCanvas.error {
        border: 3px dotted red; }
  .volunteer-registration .captcha.error iframe {
    border: 1px solid red; }

.service {
  padding: 20px 50px;
  width: 80%;
  margin: 10px auto;
  background-color: white;
  border-radius: 10px; }
  .service p.title {
    font-size: 35px;
    margin: 0 0 9px 0; }
  .service p.seattitle {
    font-size: 20px;
    font-weight: 100; }
  .service p.seatnumber {
    font-size: 46px;
    margin: -22px 0 4px 0;
    font-weight: 900; }
  .service .barcode svg {
    height: 100px; }
  .service .Registration__form {
    margin-top: 20px; }
  .service .captcha {
    margin: 0 0 10px 0; }
    .service .captcha div {
      margin: auto; }
  .service.teCuidaInfo .boolean-questions span.options {
    display: inline-block;
    width: 25%;
    vertical-align: top; }
    .service.teCuidaInfo .boolean-questions span.options .checkbox label {
      padding: 0 24px; }
  .service.teCuidaInfo .boolean-questions label {
    display: inline-block;
    width: 75%; }
    .service.teCuidaInfo .boolean-questions label.error {
      color: red; }
  .service.teCuidaInfo .boolean-questions div {
    margin: 10px 0; }
  .service.teCuidaInfo .boolean-questions button {
    margin: 10px 0; }
  .service.summary {
    background-color: white;
    text-align: center; }
  .service.selectAService .checks {
    text-align: center; }
  .service.selectAService .dates button {
    position: relative;
    width: 150px;
    height: 54px; }
    .service.selectAService .dates button .time {
      position: absolute;
      top: 12px;
      font-size: 18px; }
    .service.selectAService .dates button .left,
    .service.selectAService .dates button .center {
      position: absolute;
      font-size: 10px;
      bottom: 6px;
      font-weight: 100; }
    .service.selectAService .dates button .center {
      left: 50px;
      bottom: 3px; }
  @media only screen and (max-width: 800px) {
    .service h1,
    .service h2 {
      text-align: center; }
    .service .AdminTable {
      padding: 0; } }
  @media only screen and (max-width: 600px) {
    .service {
      padding: 20px;
      width: 100%;
      margin: 0 auto; } }

