@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:300,400,700&display=swap');

:root {
  font-size: $font-size__default;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0;
  font-size: $font-size__default;
}

h1,
h2,
h3,
h4,
h5,
p,
label,
input,
textarea,
button,
a {
  font-family: $font__default !important;
}

.App {
  @include grid(100px 1fr, 1fr);
  min-height: 100vh;
  background-color: #dedede;
}
.Content__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
}

form {
  background-color: white;
  border-radius: 5px;
}

.Registration__form {
  padding: 20px;
  .Registration__input {
    width: 33%;
  }
}
@media only screen and (max-width: 700px) {
  .Registration__input,
  input,
  .input {
    display: block;
    width: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  .input {
    padding: 5px !important;
  }
}
