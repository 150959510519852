.Button {
  background-color: $color__cgl;
  border-radius: 0.6rem;
  border: 0;
  color: $color__white;
  outline: none;
  padding: 0.5rem 1rem;
  font-weight: $font--bold;
  font-size: inherit;
  cursor: pointer;
  transition: 300ms;
  width: 100%;
  max-width: 15rem;
  margin: 15px 0 15px 0;
  text-align: center;

  &:hover {
    background-color: $color__black;
  }

  &:disabled {
    background-color: $color__grey;
    color: $color__grey-dark;
  }

  &--tag {
    max-width: 50%;
  }
}
