h1,
h2 {
  margin: 0;
}
p {
  small {
    font-size: 0.75em;
  }
}
@import './settings.scss';
@import './Layout.scss';
@import './components/Home.scss';
@import './components/AdminTable.scss';
@import './components/Button.scss';
@import './components/CheckIn.scss';
@import './components/Header.scss';
@import './components/Registration.scss';
@import './components/Tag.scss';
@import './components/Barcode.scss';
@import './components/Dates.scss';
@import '../../containers/volunteer/volunteerReg.scss';
@import '../../containers/church-members/church-service/reservation.scss';
