$color__cgl: #2596c2;
$color__black: #222;
$color__grey-dark: #a3a3a3;
$color__grey: #d3d3d3;
$color__bg: #efefef;
$color__white: #fff;

$font__title: 'Montserrat', sans-serif;
$font__default: 'Montserrat', sans-serif;
$font--light: 300;
$font--regular: 400;
$font--bold: 700;
$font-size__default: 20px;

// MIXINS:

@mixin grid($rows: 1fr, $columns: 1fr) {
  display: grid;
  grid-template: $rows / $columns;
}
